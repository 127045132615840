import stripTags from 'striptags'

export default {
    methods: {
        setInitialDocumentValues() {
            if (!window.doc) return

            let initialDoc = {
                filename: window.doc.title ? window.doc.title.replace(/<\/?[^>]+(>|$)/g, '') : '',
                title: window.doc.title ? window.doc.title : '',
            }

            if (window.doc.copy_of_document_id) {
                initialDoc = {
                    ...initialDoc,
                    filename: stripTags(
                        `Copy of ${window.doc.user_id === window.user?.id ? window.doc.filename : window.doc.title}`,
                    ),
                    entity: window.entity,
                }
            }

            if (window.user && window.doc.user_id !== window.user.id) {
                if (window.user?.documents?.length) {
                    let lastUserDoc = window.user.documents.slice(-1)[0]
                    initialDoc = {
                        ...initialDoc,
                        subject: lastUserDoc.subject,
                        grade_levels: lastUserDoc.grade_levels,
                    }
                }
            }

            this.$store.dispatch('document/setDocument', initialDoc)
        },
    },
}
